export const firebaseConfig = {
   apiKey: "AIzaSyBZosgZga4b4zLo5rVN_n8J82Y_ugB3_pg",
   authDomain: "marinella-73b50.firebaseapp.com",
   projectId: "marinella-73b50",
   databaseURL: "https://marinella-73b50.firebaseio.com",
   storageBucket: "marinella-73b50.appspot.com",
   messagingSenderId: "314034281870",
   appId: "1:314034281870:web:8c490643b677e65f0c58df",
};
// bd pruebas
// export  const firebaseConfig = {
//     apiKey: "AIzaSyBBo2XpKBwbGm9Xe3gG_vTpbqWY95FgiIc",
//     authDomain: "pruebas-cms-marinela.firebaseapp.com",
//     projectId: "pruebas-cms-marinela",
//     storageBucket: "pruebas-cms-marinela.appspot.com",
//     messagingSenderId: "740592087683",
//     appId: "1:740592087683:web:6017917f5114862494e1b6"
// }
