import React, { useState, useEffect } from "react";
import { Button, Layout, Menu, Drawer } from "antd";
import styles from "./admin.module.css";
import {
   FileDoneOutlined,
   FileSyncOutlined,
   BarsOutlined,
   HomeOutlined,
   LogoutOutlined,
   MenuOutlined,
   FileTextOutlined
} from "@ant-design/icons";
import ContentInicio from "./contents/inicio/index";
import ContentInventario from "./contents/inventario de premios/index";
import ViewOrderRegister from "./contents/ordenesProductos/ordenesPorAprobar/index";
import OrdersToDispatch from "./contents/ordenesProductos/ordenesPorDespachar/index";
import OrderApproved from "./contents/ordenesProductos/ordenesDespachadas/index";
import Jugadores from "./contents/jugadores";
import { useHistory, useLocation } from "react-router-dom";
import { useFirebaseApp } from "reactfire";

const { Header, Content, Footer, Sider } = Layout;

const AdminLayout = () => {
   let history = useHistory();
   let fb = useFirebaseApp();
   const location = useLocation();

   const curr_tab = sessionStorage.getItem("tab")
      ? parseInt(sessionStorage.getItem("tab"))
      : 1;

   let [collapsed, setCollapsed] = useState(false);
   let [visible, setVisible] = useState(false);
   let [tab, setTab] = useState(curr_tab);
   let [nameadmin, setNameadmin] = useState();

   useEffect(() => {
      setNameadmin(location.state?.name);
   }, [location]);

   const showDrawer = () => {
      setVisible(true);
   };
   const onClose = () => {
      setVisible(false);
   };

   const OpenSider = () => {
      if (collapsed) {
         setCollapsed(false);
      } else {
         setCollapsed(true);
      }
   };

   const ChangeTab = (tab) => {
      setTab(tab);
      tab !== 6 ? sessionStorage.setItem("tab", tab) : sessionStorage.setItem("tab", 1);
      if (tab === 6) {
         Logout();
      }
   };

   const Logout = () => {
      fb.auth()
         .signOut()
         .then(function () {
            history.push("/login");
            localStorage.clear();
            sessionStorage.clear();
         })
         .catch(function (error) {
            // An error happened.
         });
   };

   return (
      <Layout style={{ minHeight: "100vh" }}>
         <Sider
            style={{ background: "#1890FF !important" }}
            collapsible
            collapsed={collapsed}
            onCollapse={() => OpenSider()}
         >
            <div className="logo">
               <h1 className={styles.LogoH1}>{nameadmin}</h1>
            </div>

            <Menu defaultSelectedKeys={[`${curr_tab}`]} mode="inline">
               <Menu.Item onClick={() => ChangeTab(1)} key="1" icon={<HomeOutlined />}>
                  Inicio
               </Menu.Item>
               <Menu.Item onClick={() => ChangeTab(2)} key="2" icon={<BarsOutlined />}>
                  Inventario
               </Menu.Item>
               <Menu.Item onClick={() => ChangeTab(3)} key="3" icon={<FileTextOutlined />}>
                  Órdenes por aprobar
               </Menu.Item>
               <Menu.Item onClick={() => ChangeTab(4)} key="4" icon={<FileSyncOutlined />}>
                  Órdenes por despachar
               </Menu.Item>
               <Menu.Item
                  onClick={() => ChangeTab(5)}
                  key="5"
                  icon={<FileDoneOutlined />}
               >
                  Órdenes despachadas
               </Menu.Item>
               <Menu.Item onClick={() => ChangeTab(6)} key="6" icon={<LogoutOutlined />}>
                  Cerrar sesion
               </Menu.Item>
               {/* <Menu.Item
            onClick={() => ChangeTab(20)}
            key="20"
            icon={<UsergroupDeleteOutlined  />}
          >
            JUGADORES
          </Menu.Item> */}
               {/*

         <Menu.Item
            onClick={() => ChangeTab(5)}
            key="5"
            icon={<FileExclamationOutlined />}
          >
            Facturas rechazadas
          </Menu.Item> */}
            </Menu>
         </Sider>
         <Layout
            style={{ backgroundColor: "#EF3829 !important", position: "relative" }}
            className="site-layout"
         >
            <Header
               style={{ backgroundColor: "#EF3829 !important" }}
               className="site-layout-background"
               // style={{ padding: 0 }}
            />
            <div
               style={{ position: "absolute", top: "2%", left: "2%" }}
               className={styles.buttonCollapse}
            >
               <Button onClick={showDrawer}>
                  <MenuOutlined />
               </Button>
            </div>
            <Drawer
               title="Menu"
               placement="left"
               closable={false}
               onClose={onClose}
               visible={visible}
            >
               <Menu defaultSelectedKeys={[`${curr_tab}`]} mode="inline">
                  <Menu.Item onClick={() => ChangeTab(1)} key="1" icon={<HomeOutlined />}>
                     Inicio
                  </Menu.Item>

                  <Menu.Item onClick={() => ChangeTab(2)} key="2" icon={<BarsOutlined />}>
                     Inventario
                  </Menu.Item>
                  <Menu.Item
                     onClick={() => ChangeTab(3)}
                     key="3"
                     icon={<FileTextOutlined />}
                  >
                     Órdenes por aprobar
                  </Menu.Item>
                  <Menu.Item
                     onClick={() => ChangeTab(4)}
                     key="4"
                     icon={<FileSyncOutlined />}
                  >
                     Órdenes por despachar
                  </Menu.Item>
                  <Menu.Item
                     onClick={() => ChangeTab(5)}
                     key="5"
                     icon={<FileDoneOutlined />}
                  >
                     Órdenes despachadas
                  </Menu.Item>

                  <Menu.Item
                     onClick={() => ChangeTab(6)}
                     key="6"
                     icon={<LogoutOutlined />}
                  >
                     Cerrar sesion
                  </Menu.Item>

               </Menu>
            </Drawer>
            <Content style={{ margin: "0 16px" }}>
               {tab === 1 ? (
                  <ContentInicio />
               ) : tab === 2 ? (
                  <ContentInventario />
               ) : tab === 3 ? (
                  <ViewOrderRegister nameadmin={nameadmin} />
               ) : tab === 4 ? (
                  <OrdersToDispatch nameadmin={nameadmin} />
               ) : tab === 5 ? (
                  <OrderApproved nameadmin={nameadmin} />
               ) : (
                  tab === 20 && <Jugadores />
               )}
            </Content>

            <Footer style={{ textAlign: "center" }}>
               MARINELA - LO IMPORTANTE ES LO DE ADENTRO - 2021
            </Footer>
         </Layout>
      </Layout>
   );
};

export default AdminLayout;
