import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { FirebaseAppProvider, SuspenseWithPerf } from "reactfire";
import { firebaseConfig } from "./firebase";
import { Spin, Row } from "antd";


ReactDOM.render(
  <React.StrictMode>
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
  <SuspenseWithPerf
      fallback={
        <div className="divPreloader">
          <Row justify="center">
            <Spin size="large" tip="Cargando..."></Spin>
          </Row>
        </div>
      }
    >
    <App />
    </SuspenseWithPerf>
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

