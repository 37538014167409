import InformationCard from "./informationCards";
import DataTable from "./dataTable";

const ContentInicio = () => {
  return (
    <>
      <InformationCard />
      {/* <DataTable /> */}
    </>
  );
};

export default ContentInicio;
