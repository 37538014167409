import { Row, Col } from "antd";
import ViewRegisterPremio from "./ViewRegisterPremio";

const ContentPlazas = () => {
  return (
    <Row>
      <Col span={24} style={{ marginTop: "10px" }}>
        <ViewRegisterPremio  />
      </Col>
    </Row>
  );
};

export default ContentPlazas;
