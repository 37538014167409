import React, { useEffect, useState } from "react";
import { Table, Tooltip, Space, Divider, Button, Tag } from "antd";
import {
   CheckCircleOutlined,
   StopOutlined,
   ReloadOutlined,
   SyncOutlined,
} from "@ant-design/icons";
import "firebase/firestore";
import { MSJERROR, MSJOK } from "../../../mensajes/index";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "./index.css";
import { useFirestore } from "reactfire";
import moment from "moment";

const { Column } = Table;

const ViewOrderRegister = ({ nameadmin }) => {
   let [dataOrders, setDataOrders] = useState();
   let [updateOrders, setUpdateOrders] = useState(false);
   let [update, setUpdate] = useState(false);
   let [datapremios, setDatapremios] = useState();
   let [loadingData, setLoadingData] = useState(true);

   let db = useFirestore();

   useEffect(() => {
      const url =
         "https://api.evius.co/api/organization/60467fbd9caef512a5626fc9/ordersUsersPoints?status=pendiente";
      const GetOrdersToDispatch = async () => {
         fetch(url, {
            method: "GET",
            headers: {
               "Content-Type": "application/json",
            },
         })
            .then(function (response) {
               return response.json();
            })
            .then(function (data) {
               setDataOrders(data);
               setLoadingData(false);
               setUpdate(false);
            })
            .catch(function (error) {
               console.log(error);
               setLoadingData(false);
               setUpdate(false);
            });
      };
      GetOrdersToDispatch();
   }, [updateOrders]);

   useEffect(() => {
      db.collection("inventario_prem").onSnapshot(function (querySnapshot) {
         var premios = [];
         querySnapshot.forEach(function (doc) {
            premios.push(doc.data());
         });
         setDatapremios(premios);
      });
   }, []);

   function HandleAprove(order) {
      Swal.fire({
         title: "Aprobar orden",
         text: "¿Estás seguro de aprobar esta orden",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#2ECC71 ",
         cancelButtonColor: "#E74C3C",
         confirmButtonText: "Si, Aprobar orden",
      }).then((result) => {
         if (result.isConfirmed) {
            const OrderShipped = async () => {
               const url = `https://api.evius.co/api/orders/${order._id}`;
               const completedStatus = "VALID";
               //body del request
               const dataSend = {
                  status: completedStatus,
               };
               //Preparación del request
               const headers = { "Content-Type": "application/json" };
               const body = JSON.stringify(dataSend);
               //Ejecución del request
               try {
                  const urlApi = `${url}`;
                  const res = await fetch(urlApi, {
                     method: "PUT",
                     headers,
                     body,
                  });
                  if (res.status === 200) {
                     MSJOK("Orden aprobada correctamente");

                     let dataOrdersUser = dataOrders.filter(
                        (userOrders) => userOrders._id !== order._id
                     );
                     setDataOrders(dataOrdersUser);
                  } else {
                     MSJERROR("Ha ocurrido un error, intentelo mas tarde");
                  }
               } catch (err) {
                  MSJERROR("Ha ocurrido un error, intentelo mas tarde");
               }
            };
            setTimeout(() => {
               OrderShipped();
            }, 2500);
         }
      });
   }

   function HandleRejected(order) {
      Swal.fire({
         title: "Rechazar orden",
         text: "¿Estás seguro de rechazar esta orden",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#2ECC71 ",
         cancelButtonColor: "#E74C3C",
         confirmButtonText: "Si, Rechazar orden",
      }).then((result) => {
         if (result.isConfirmed) {
            const productName = order.product.trim();
            let filtroDataPremios = datapremios.filter((premio) => {
               return premio.nombre.trimEnd() === productName;
            });
            let dataFiltered = filtroDataPremios[0].nombre;
            let sizeSelectUnit;
            let sizeSelect = order.talla;
            let units = filtroDataPremios[0].unidades;
            let unit = units + 1;

            switch (sizeSelect) {
               case "xs":
                  sizeSelectUnit = filtroDataPremios[0].xs + 1;
                  break;
               case "s":
                  sizeSelectUnit = filtroDataPremios[0].s + 1;
                  break;
               case "m":
                  sizeSelectUnit = filtroDataPremios[0].m + 1;
                  break;
               case "l":
                  sizeSelectUnit = filtroDataPremios[0].l + 1;
                  break;
               case "xl":
                  sizeSelectUnit = filtroDataPremios[0].xl + 1;
                  break;
               case "unica":
                  sizeSelectUnit = filtroDataPremios[0].unica + 1;
                  break;
               default:
                  break;
            }
            function updateSize() {
               db.collection("inventario_prem")
                  .doc(dataFiltered)
                  .update({
                     unidades: unit,
                     [sizeSelect]: sizeSelectUnit,
                  });
            }
            updateSize();

            const OrderRejected = async () => {
               const url = `https://api.evius.co/api/orders/${order._id}`;
               const completedStatus = "REJECTED";
               //body del request
               const dataSend = {
                  status: completedStatus,
               };
               //Preparación del request
               const headers = { "Content-Type": "application/json" };
               const body = JSON.stringify(dataSend);
               //Ejecución del request
               try {
                  const urlApi = `${url}`;
                  const res = await fetch(urlApi, {
                     method: "PUT",
                     headers,
                     body,
                  });
                  if (res.status === 200) {
                     MSJOK("Orden rechazada correctamente");
                     let dataOrdersUser = dataOrders.filter(
                        (userOrders) => userOrders._id !== order._id
                     );
                     setDataOrders(dataOrdersUser);
                  } else {
                     MSJERROR("Ha ocurrido un error, intentelo mas tarde");
                  }
               } catch (err) {
                  MSJERROR("Ha ocurrido un error, intentelo mas tarde");
               }
            };
            setTimeout(() => {
               OrderRejected();
            }, 2500);
         }
      });
   }
   const HandleReload = () => {
      setUpdate(true);
      setLoadingData(true);
      setTimeout(() => {
         setUpdateOrders(!updateOrders);
      }, 8000);
   };
   return (
      <>
         <h2
            style={{
               color: "#9B9997",
               fontSize: "25px",
               fontWeight: "bold",
            }}
         >
            {" "}
            LISTADO DE ÓRDENES POR APROBAR
         </h2>
         <Button
            style={{
               color: "#ffffff",
               fontSize: "15px",
               fontWeight: "bold",
            }}
            type="primary"
            onClick={() => HandleReload()}
         >
            {update === false ? (
               <h4
                  style={{
                     color: "#FFFFFF",
                  }}
               >
                  <ReloadOutlined /> Actualizar listado
               </h4>
            ) : (
               <h4
                  style={{
                     color: "#FFFFFF",
                  }}
               >
                  <SyncOutlined spin /> Actualizando...
               </h4>
            )}
         </Button>
         <Divider />
         <Table
            bordered
            dataSource={dataOrders}
            size="small"
            scroll={{ x: 1300 }}
            loading={loadingData}
            pagination={{ position: ["bottomCenter"] }}
         >
            <Column
               title="Nombres"
               dataIndex="names"
               key="names"
               align="center"
               fixed="left"
            />
            <Column
               title="Email"
               dataIndex="email"
               key="email"
               align="center"
               fixed="left"
            />
            <Column
               title="Documento"
               dataIndex="document_cumber"
               key="document_cumber"
               align="center"
            />
            <Column title="Producto" dataIndex="product" key="product" align="center" />
            <Column title="Talla" dataIndex="talla" key="talla" align="center" />
            <Column
               title="Estado"
               key="status"
               align="center"
               render={(data) => {
                  return data.status === "CORRECTO" ? (
                     <Tag color="green">{data.status}</Tag>
                  ) : (
                     <Tag color="red">{data.status}</Tag>
                  );
               }}
            />
            <Column
               title="Puntos antes de la redención"
               dataIndex="codes_before"
               key="codes_before"
               align="center"
            />
            <Column
               title="Costo del producto"
               dataIndex="product_points"
               key="product_points"
               align="center"
            />
            <Column
               title="Total puntos por codigos redimidos"
               dataIndex="total_codes"
               key="total_codes"
               align="center"
            />
            <Column
               title="Total puntos por prendas canjeadas"
               dataIndex="total_orders"
               key="total_orders"
               align="center"
            />
            <Column
               title="Fecha de creación"
               key="date_order"
               align="center"
               sorter={(a, b) => {
                  let c = new Date(a.date_order);
                  let d = new Date(b.date_order);
                  return c - d;
               }}
               render={(data) => {
                  return <p>{moment(data.date_order).format("LLL")}</p>;
               }}
            />
            <Column
               title="Herramientas"
               key="operation"
               fixed="right"
               align="center"
               width={245}
               render={(order, index) => (
                  <Space key={index} size="small">
                     <Tooltip key={index} placement="top" title="Aprobar">
                        <Button
                           key={index}
                           onClick={() => HandleAprove(order)}
                           size="default"
                           type="default"
                           icon={<CheckCircleOutlined />}
                           className="CheckOutlined"
                        >
                           Aprobar
                        </Button>
                     </Tooltip>
                     <Tooltip key={index} placement="top" title="Rechazar">
                        <Button
                           key={index}
                           onClick={() => HandleRejected(order)}
                           size="default"
                           type="default"
                           icon={<StopOutlined />}
                           className="DangerOutlined"
                        >
                           Rechazar
                        </Button>
                     </Tooltip>
                  </Space>
               )}
            />
         </Table>
      </>
   );
};

export default ViewOrderRegister;
