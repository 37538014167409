import { Table, Row,Col ,Divider, Button } from "antd";
import { UserSwitchOutlined,OrderedListOutlined  } from "@ant-design/icons";
import "firebase/firestore";
import { useFirestore } from "reactfire";
import "react-toastify/dist/ReactToastify.css";
import InfoPlayers from "../inicio/infoplayers";
import ReactExport from "react-export-excel";
import React, { useEffect, useState } from "react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Jugadores = () => {
  let db = useFirestore();
  let [dataplayers, setdataplayers] = useState();
  let [dataranking, setdataranking] = useState();


  useEffect(() => {
    const players = [];
    db.collection("jugadores").orderBy("puntos")
    .get()
    .then(querySnapshot => {
      const data = querySnapshot.docs.map(doc => doc.data());

      data.map((jugador, index) => {
        players.push(jugador);
        return true;
      })



        //ordenamiento de ranking
      var arrayOrdenado = [];
      for (var key in players) {
        arrayOrdenado.push(players[key]);
  
      }
      arrayOrdenado.sort(function (a, b) {
        return b.puntos - a.puntos;
      });
  
      var rank = 1;
      for (var i = 0; i < arrayOrdenado.length; i++) {
        if (i > 0 && arrayOrdenado[i].puntos < arrayOrdenado[i - 1].puntos
          && arrayOrdenado[i].puntos!==0) {
          rank++;
        }
        arrayOrdenado[i].rank = rank;
      }
      

      let arrayFinal = arrayOrdenado.slice(0,10);
      setdataranking(arrayFinal);
      setdataplayers(arrayOrdenado);


    });
    
  }, []);

  const columns = [
    {
      title: "cedula",
      dataIndex: "cedula",
      sorter: (a, b) => a.cedula - b.cedula,
      sortDirections: ["descend"],
    },
    {
      title: "nombre",
      dataIndex: "nombre",
    },
    {
      title: "celular",
      dataIndex: "celular",
    },
    {
      title: "correo",
      dataIndex: "correo",
      filterMultiple: false,
      sorter: (a, b) => a.correo - b.correo,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "puntos",
      dataIndex: "puntos",
      key: "puntos",
      sorter: (a, b) => a.puntos - b.puntos,
      ellipsis: true,
    },
  ];

  return (
    <>
      <h2
        style={{
          color: "#9B9997",
          float: "right",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {" "}
        LISTADO DE JUGADORES
      </h2>
      <InfoPlayers />
      <Divider />
      <Row>
        <Col>
        <ExcelFile
        element={
          <Button type="primary" shape="round" icon={<UserSwitchOutlined />}>
            Exportar todos los jugadores
          </Button>
        }
      >
        <ExcelSheet data={dataplayers} name="Jugadores Cazastickers">
          <ExcelColumn label="cedula" value="cedula" />
          <ExcelColumn label="nombre" value="nombre" />
          <ExcelColumn label="celular" value="celular" />
          <ExcelColumn label="correo" value="correo" />
          <ExcelColumn label="puntos" value="puntos" />
        </ExcelSheet>
      </ExcelFile>
       </Col>

       <Col>
        <ExcelFile
        element={
          <Button style={{marginLeft:"20px"}} type="primary" shape="round" icon={<OrderedListOutlined  />}>
            Exportar los 10 mejores jugadores
          </Button>
        }
      >
        <ExcelSheet data={dataranking} name="RANKING Cazastickers">
          <ExcelColumn label="cedula" value="cedula" />
          <ExcelColumn label="nombre" value="nombre" />
          <ExcelColumn label="celular" value="celular" />
          <ExcelColumn label="correo" value="correo" />
          <ExcelColumn label="puntos" value="puntos" />
        </ExcelSheet>
      </ExcelFile>
       </Col>


      </Row>
     
      

      <Table columns={columns} dataSource={dataplayers} />
    </>
  );
};

export default Jugadores;
