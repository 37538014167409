import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "antd";
import {
   FileDoneOutlined,
   // UsergroupDeleteOutlined,
   // ExceptionOutlined,
   ReloadOutlined,
   FileAddOutlined,
   WarningFilled,
   SkinOutlined,
   SyncOutlined,
} from "@ant-design/icons";
import "firebase/firestore";
import { useFirestore } from "reactfire";

const { Meta } = Card;

const InformationCard = () => {
   let db = useFirestore();

   let [datapremios, setDatapremios] = useState();
   let [datapremiosescasos, setDatapremiosescasos] = useState();
   let [dataOrdersPending, setDataOrdersPending] = useState();
   let [dataOrdersCompleted, setDataOrdersCompleted] = useState();
   let [updateCards, setUpdateCards] = useState(false);
   let [update, setUpdate] = useState(false);

   useEffect(() => {
      db.collection("inventario_prem").onSnapshot(function (querySnapshot) {
         let premios = [];
         let premiosEscasos = [];
         querySnapshot.forEach(function (doc) {
            doc.data().unidades <= doc.data().cant_minima
               ? premiosEscasos.push(doc.data())
               : premios.push(doc.data());
         });
         setDatapremios(premios);
         setDatapremiosescasos(premiosEscasos);
         // console.log(plazas);
      });
   }, []);

   useEffect(() => {
      const url =
         "https://api.evius.co/api/orders/60467fbd9caef512a5626fc9/orderOrganization";
      const GetOrdersToDispatch = async () =>{
         fetch(url, {
            method: "GET",
            headers: {
               "Content-Type": "application/json",
            },
         })
            .then(function (response) {
               return response.json();
            })
            .then(function (data) {
               let pendingStatus = "5c4a299c5c93dc0eb199214a";
               let completedStatus = "5c423232c9a4c86123236dcd";

               let dataOrdersUserCompletedStatus = data.filter(
                  (userOrders) => userOrders.order_status_id === completedStatus
               );
               let orderPropertiesCompletedStatus = [];
               dataOrdersUserCompletedStatus.map(function (items) {
                  orderPropertiesCompletedStatus.push({
                     ...items.properties,
                     orderId: items._id,
                     orderStatusId: items.order_status_id,
                  });
                  return orderPropertiesCompletedStatus;
               });

               let dataOrdersUserPendingStatus = data.filter(
                  (userOrders) => userOrders.order_status_id === pendingStatus
               );
               let orderPropertiesPendingStatus = [];
               dataOrdersUserPendingStatus.map(function (items) {
                  orderPropertiesPendingStatus.push({
                     ...items.properties,
                     orderId: items._id,
                     orderStatusId: items.order_status_id,
                  });
                  return orderPropertiesPendingStatus;
               });

               setDataOrdersPending(orderPropertiesPendingStatus);
               setDataOrdersCompleted(orderPropertiesCompletedStatus);
            })
            .catch(function (error) {
               console.log(error);
            });
      }
      GetOrdersToDispatch();
   }, [updateCards]);
   const HandleReload = () => {
      setUpdate(true);
      setTimeout(() => {
         setUpdateCards(!updateCards);
         setUpdate(false);
      }, 8000);
   };
   return (
      <>
         <h2
            style={{
               color: "#9B9997",
               fontSize: "25px",
               fontWeight: "bold",
            }}
         >
            DASHBOARD
         </h2>
         <Button
            style={{
               color: "#ffffff",
               fontSize: "15px",
               fontWeight: "bold",
            }}
            type="primary"
            onClick={() => HandleReload()}
         >
            {update === false ? (
               <h4
                  style={{
                     color: "#FFFFFF",
                     // fontSize: "21px",
                  }}
               >
                  <ReloadOutlined /> Actualizar listado
               </h4>
            ) : (
               <h4
                  style={{
                     color: "#FFFFFF",
                     // fontSize: "21px",
                  }}
               >
                  <SyncOutlined spin /> Actualizando...
               </h4>
            )}
         </Button>
         <div className="site-card-wrapper">
            <Row gutter={16}>
               <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Card>
                     <Meta
                        avatar={
                           <FileAddOutlined
                              style={{
                                 color: "white",
                                 fontSize: "25px",
                                 marginBottom: "25px",
                                 alignText: "center",
                              }}
                           />
                        }
                        title="ÓRDENES NUEVAS"
                     />
                     {dataOrdersPending === undefined ? (
                        <span
                           style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "#ffffff",
                           }}
                        >
                           cargando...
                        </span>
                     ) : (
                        <span
                           style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                              color: "#ffffff",
                           }}
                        >
                           {dataOrdersPending.length}
                        </span>
                     )}
                  </Card>
                  <Card>
                     <Meta
                        avatar={
                           <FileDoneOutlined
                              style={{
                                 color: "white",
                                 fontSize: "25px",
                                 marginBottom: "25px",
                                 alignText: "center",
                              }}
                           />
                        }
                        title="ÓRDENES DESPACHADAS"
                     />
                     {dataOrdersCompleted === undefined ? (
                        <span
                           style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "#ffffff",
                           }}
                        >
                           cargando...
                        </span>
                     ) : (
                        <span
                           style={{
                              fontSize: "25px",
                              fontWeight: "bold",
                              color: "#ffffff",
                           }}
                        >
                           {dataOrdersCompleted.length}
                        </span>
                     )}
                  </Card>
               </Col>
               <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Card>
                     <Meta
                        avatar={
                           <SkinOutlined
                              style={{
                                 color: "white",
                                 fontSize: "25px",
                                 marginBottom: "25px",
                              }}
                           />
                        }
                        title="PREMIOS"
                     />
                     {/* <h1 style={{fontSize: '30px', color: 'white'}}>{datplayers && datplayers.length}</h1> */}
                     {datapremios &&
                        datapremios.map((value, key) => (
                           <ul style={{ fontSize: "15px", color: "white" }}>
                              {key + 1}. {value.nombre} : {value.unidades} unidades
                           </ul>
                        ))}
                  </Card>
               </Col>
               <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                  <Card>
                     <Meta
                        avatar={
                           <WarningFilled
                              style={{
                                 color: "white",
                                 fontSize: "25px",
                                 marginBottom: "25px",
                              }}
                           />
                        }
                        title="ALERTAS DE INVENTARIO"
                     />
                     {/* <h1 style={{fontSize: '30px', color: 'white'}}>{factu
        nconfirmed && factunconfirmed.length}</h1> */}
                     {datapremiosescasos &&
                        datapremiosescasos.map((value, key) => (
                           <ul style={{ fontSize: "15px", color: "white" }}>
                              {key + 1}. {value.nombre}: {value.unidades}{" "}
                              <span
                                 style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                    color: "#eb5534",
                                 }}
                              >
                                 Escaso
                              </span>
                           </ul>
                        ))}
                  </Card>
               </Col>
            </Row>
         </div>
      </>
   );
};

export default InformationCard;
