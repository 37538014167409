import { Form, Input, Button, Row } from "antd";
import { RightCircleOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { useFirebaseApp, useFirestore } from "reactfire";
import { useHistory } from "react-router-dom";
import { MSJERROR } from "../admin/mensajes/index";
import "./login.scss";

const LoginClaro = () => {
   let fb = useFirebaseApp();
   let history = useHistory();
   let db = useFirestore();

   const onFinish = (values) => {
      const { email, clave } = values;
      let usersadmin = [];
      let usersinv = [];
      db.collection("administradores")
         .get()
         .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
               usersadmin.push(doc.data());
            });
            const existuser = usersadmin.filter((user) => user.email === email);
            if (existuser.length === 0) {
               db.collection("invitados")
                  .get()
                  .then(function (querySnapshot) {
                     querySnapshot.forEach(function (doc) {
                        usersinv.push(doc.data());
                     });
                     const existuser = usersinv.filter((user) => user.email === email);
                     if (existuser.length === 0) {
                        MSJERROR("ESTE USUARIO NO ES ADMINISTRADOR NI UN INVITADO");
                     } else if (existuser.length > 0) {
                        sessionStorage.setItem("userInv", existuser[0].rol);
                        existuser.map((user) => {
                           if (user.email === email && user.clave === clave) {
                              history.push({
                                 pathname: "/invited",
                                 state: { name: user.nombre },
                              });
                              window.location.reload();
                           } else {
                              MSJERROR("CREDENCIALES INCORRECTAS");
                           }
                        });
                     }
                  });
            } else if (existuser.length > 0) {
               sessionStorage.setItem("userAdmin", existuser[0].rol);
               existuser.map((user) => {
                  if (user.email === email && user.clave === clave) {
                     history.push({
                        pathname: "/admin",
                        state: { name: user.nombre },
                     });
                     window.location.reload();
                  } else {
                     MSJERROR("CREDENCIALES INCORRECTAS");
                  }
               });
            }
         });
   };

   const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
   };

   return (
      <>
         <Form
            className="form-login"
            name="basic"
            initialValues={{
               remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
         >
            <Form.Item
               name="email"
               rules={[
                  {
                     required: true,
                     message: "Digite su correo electronico",
                  },
               ]}
            >
               <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Email"
               />
            </Form.Item>

            <Form.Item
               name="clave"
               rules={[
                  {
                     required: true,
                     message: "Digite su clave",
                  },
               ]}
            >
               <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder="Clave"
               />
            </Form.Item>

            <Form.Item className="Boton_login">
               <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  icon={<RightCircleOutlined />}
                  size="large"
               >
                  ENTRAR
               </Button>
            </Form.Item>
         </Form>
      </>
   );
};

export default LoginClaro;
