import { toast } from "react-toastify";

export const MSJOK = (msj) => {
  toast.configure();
  toast.success(msj, {
    position: "top-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    pauseOnFocusLoss:false,
    draggable: true,
    // progress: undefined,
  });
};

export const MSJERROR = (msj) => {
  toast.configure();
  toast.error(msj, {
    position: "top-right",
    autoClose: 3500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};


