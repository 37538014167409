import React, { useEffect, useState } from "react";
import { Table, Tooltip, Space, Divider, Button } from "antd";
import { CheckCircleOutlined, ReloadOutlined, SyncOutlined } from "@ant-design/icons";
import "firebase/firestore";
import { MSJERROR, MSJOK } from "../../../mensajes/index";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "./index.css";

const { Column } = Table;

const OrdersToDispatch = ({ nameadmin }) => {
   let [dataOrders, setDataOrders] = useState();
   let [updateOrders, setUpdateOrders] = useState(false);
   let [update, setUpdate] = useState(false);
   let [loadingData, setLoadingData] = useState(true);

   useEffect(() => {
      const url =
         "https://api.evius.co/api/orders/60467fbd9caef512a5626fc9/orderOrganization";
      const GetOrdersToDispatch = async () => {
         fetch(url, {
            method: "GET",
            headers: {
               "Content-Type": "application/json",
            },
         })
            .then(function (response) {
               return response.json();
            })
            .then(function (data) {
               let pendingStatus = "613ff0c1f1c6df84356b30c2";
               let dataOrdersUser = data.filter(
                  (userOrders) => userOrders.order_status_id === pendingStatus
               );
               let orderProperties = [];
               dataOrdersUser.map(function (items) {
                  orderProperties.push({
                     ...items.properties,
                     orderId: items._id,
                     orderStatusId: items.order_status_id,
                     account_points: items.account_points,
                     amount: items.amount,
                  });
                  return orderProperties;
               });
               setDataOrders(orderProperties);
               setLoadingData(false);
               setUpdate(false);
            })
            .catch(function (error) {
               console.log(error);
               setLoadingData(false);
               setUpdate(false);
            });
      };
      GetOrdersToDispatch();
   }, [updateOrders]);

   function HandleAprove(order) {
      Swal.fire({
         title: "Despachar orden",
         text: "¿Estás seguro de despachar esta orden",
         icon: "warning",
         showCancelButton: true,
         confirmButtonColor: "#2ECC71 ",
         cancelButtonColor: "#E74C3C",
         confirmButtonText: "Si, Despachar orden",
      }).then((result) => {
         if (result.isConfirmed) {
            const OrderShipped = async () => {
               const url = `https://api.evius.co/api/orders/${order.orderId}`;
               const completedStatus = "APPROVED";
               //body del request
               const dataSend = {
                  status: completedStatus,
               };
               //Preparación del request
               const headers = { "Content-Type": "application/json" };
               const body = JSON.stringify(dataSend);
               //Ejecución del request
               try {
                  const urlApi = `${url}`;
                  const res = await fetch(urlApi, {
                     method: "PUT",
                     headers,
                     body,
                  });
                  if (res.status === 200) {
                     MSJOK("Orden despachada correctamente");

                     let dataOrdersUser = dataOrders.filter(
                        (userOrders) => userOrders.orderId !== order.orderId
                     );
                     setDataOrders(dataOrdersUser);
                  } else {
                     MSJERROR("Ha ocurrido un error, intentelo mas tarde");
                  }
               } catch (err) {
                  MSJERROR("Ha ocurrido un error, intentelo mas tarde");
               }
            };
            setTimeout(() => {
               OrderShipped();
            }, 2500);
         }
      });
   }

   const HandleReload = () => {
      setUpdate(true);
      setLoadingData(true);
      setTimeout(() => {
         setUpdateOrders(!updateOrders);
      }, 8000);
   };

   return (
      <>
         <h2
            style={{
               color: "#9B9997",
               fontSize: "25px",
               fontWeight: "bold",
            }}
         >
            {" "}
            LISTADO DE ÓRDENES POR DESPACHAR
         </h2>
         <Button
            style={{
               color: "#ffffff",
               fontSize: "15px",
               fontWeight: "bold",
            }}
            type="primary"
            onClick={() => HandleReload()}
         >
            {update === false ? (
               <h4
                  style={{
                     color: "#FFFFFF",
                  }}
               >
                  <ReloadOutlined /> Actualizar listado
               </h4>
            ) : (
               <h4
                  style={{
                     color: "#FFFFFF",
                  }}
               >
                  <SyncOutlined spin /> Actualizando...
               </h4>
            )}
         </Button>
         <Divider />
         <Table
            bordered
            dataSource={dataOrders}
            size="small"
            scroll={{ x: 1300 }}
            loading={loadingData}
            pagination={{ position: ["bottomCenter"] }}
         >
            <Column
               title="Nombres"
               dataIndex="nombres"
               key="nombres"
               align="center"
               fixed="left"
            />
            <Column
               title="Apellidos"
               dataIndex="apellidos"
               key="apellidos"
               align="center"
               fixed="left"
            />
            <Column
               title="Puntos antes de la redención"
               dataIndex="account_points"
               key="account_points"
               align="center"
            />
            <Column
               title="Costo del producto"
               dataIndex="amount"
               key="amount"
               align="center"
            />
            <Column
               title="Documento"
               dataIndex="documento"
               key="documento"
               align="center"
            />
            <Column title="Telefono" dataIndex="celular" key="celular" align="center" />
            <Column
               title="Departamento"
               dataIndex="departamento"
               key="departamento"
               align="center"
            />
            <Column title="Ciudad" dataIndex="ciudad" key="ciudad" align="center" />
            <Column
               title="Dirección"
               dataIndex="dirección"
               key="dirección"
               align="center"
            />
            <Column title="Barrio" dataIndex="barrio" key="barrio" align="center" />
            <Column
               title="Info. adicional"
               dataIndex="torre o casa"
               key="torre o casa"
               align="center"
            />
            <Column
               title="Producto"
               dataIndex="nombre del producto"
               key="nombre del producto"
               align="center"
            />
            <Column title="Talla" dataIndex="talla" key="talla" align="center" />
            <Column title="Cantidad" dataIndex="cantidad" key="cantidad" align="center" />
            <Column title="Color" dataIndex="color" key="color" align="center" />
            <Column
               title="Herramientas"
               key="operation"
               fixed="right"
               align="center"
               width={180}
               render={(order, index) => (
                  <Space key={index} size="small">
                     <Tooltip key={index} placement="top" title="Despachar orden">
                        <Button
                           key={index}
                           onClick={() => HandleAprove(order)}
                           size="default"
                           type="default"
                           icon={<CheckCircleOutlined c />}
                           className="CheckOutlined"
                        >
                           Despachar orden
                        </Button>
                     </Tooltip>
                  </Space>
               )}
            />
         </Table>
      </>
   );
};

export default OrdersToDispatch;
