import { Row, Col } from "antd";
import OrdersToDispatch from "./ordersToDispatch";

const Content = ({ nameadmin }) => {
   return (
      <Row>
         <Col span={24} style={{ marginTop: "10px" }}>
            <OrdersToDispatch nameadmin={nameadmin} />
         </Col>
      </Row>
   );
};

export default Content;
