import { Table, Tooltip, Space, Divider, Button, Modal, Image } from "antd";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "firebase/firestore";
import { useFirestore } from "reactfire";
import { MSJOK, MSJERROR } from "../../mensajes/index";
import FormRegister from "./FormRegister";
import "./inventario de premios general.css";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";

const { Column } = Table;

const ViewRegisterPremio = () => {
   let db = useFirestore();
   let [datapremios, setDatapremios] = useState();
   let [isOpenModal, setisOpenModal] = useState();
   let [typefunction, settypefunction] = useState();
   let [titlemodal, settitlemodal] = useState();
   let [premioform, setpremioform] = useState();

   const HandleDelete = (premio) => {
      db.collection("inventario_prem")
         .where("nombre", "==", premio.nombre)
         .get()
         .then(function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
               db.collection("inventario_prem")
                  .doc(doc.id)
                  .delete()
                  .then(function () {
                     MSJOK("Premio eliminado correctamente");
                  })
                  .catch(function (error) {
                     MSJERROR("No se pudo eliminar");
                  });
            });
         })
         .catch(function (error) {
            console.log("Error getting documents: ", error);
         });
   };

   const HandleEdit = (premio) => {
      sessionStorage.setItem("editableElement", false);
      setpremioform(premio);
      setisOpenModal(true);
      settypefunction(2);
      settitlemodal("EDITAR PREMIO");
   };

   const HandleSave = () => {
      sessionStorage.setItem("editableElement", true);
      setisOpenModal(true);
      setpremioform([]);
      settypefunction(1);
      settitlemodal("AGREGAR NUEVO PREMIO");
   };

   const handleCancel = () => {
      setisOpenModal(false);
      window.location.reload();
   };

   useEffect(() => {
      db.collection("inventario_prem").onSnapshot(function (querySnapshot) {
         var premios = [];
         querySnapshot.forEach(function (doc) {
            premios.push(doc.data());
         });
         setDatapremios(premios);
      });
   }, []);

   return (
      <>
         <h2
            style={{
               color: "#9B9997",
               fontSize: "25px",
               fontWeight: "bold",
            }}
         >
            INVENTARIO
         </h2>
         <Button
            style={{
               color: "#ffffff",
               fontSize: "15px",
               fontWeight: "bold",
            }}
            type="primary"
            onClick={() => HandleSave()}
            icon={<PlusCircleOutlined />}
         >
            {" "}
            Nuevo producto{" "}
         </Button>
         <Divider />

         <Table
            size="small"
            bordered
            dataSource={datapremios}
            pagination={{ pageSize: 5 }}
            scroll={{ x: 1300 }}
         >
            <Column title="Nombre" dataIndex="nombre" key="nombre" align="center" />
            <Column title="Descripción" dataIndex="descripcion" key="descripcion" align="center" />
            <Column title="Categoría" dataIndex="categoria" key="categoria" align="center" />
            <Column title="Color" dataIndex="color" key="color" align="center" />
            <Column
               title="Valor en puntos"
               dataIndex="valorEnPuntos"
               key="valorEnPuntos"
               align="center"
            />
            <Column title="Unidades" dataIndex="unidades" key="unidades" align="center" />
            <Column title="XS" dataIndex="xs" key="xs" align="center" />
            <Column title="S" dataIndex="s" key="s" align="center" />
            <Column title="M" dataIndex="m" key="m" align="center" />
            <Column title="L" dataIndex="l" key="l" align="center" />
            <Column title="XL" dataIndex="xl" key="xl" align="center" />
            <Column title="Única" dataIndex="unica" key="unica" align="center" />
            <Column
               title="Preview"
               key="preview"
               align="center"
               render={(premio, index) => (
                  <Space key={index} size="small">
                     <Tooltip key={index} placement="topLeft" title="Vista previa">
                        <Image width={60} src={premio.preview} />
                     </Tooltip>
                  </Space>
               )}
            />

            <Column
               title="Herramientas"
               key="action"
               align="center"
               render={(premio, index) => (
                  <Space key={index} size="large">
                     <Tooltip key={index} placement="topLeft" title="Editar">
                        <Button
                           key={index}
                           onClick={() => HandleEdit(premio)}
                           type="primary"
                           icon={<EditOutlined />}
                        />
                     </Tooltip>
                     <Tooltip key={index} placement="topLeft" title="Eliminar">
                        <Button
                           key={index}
                           onClick={() => HandleDelete(premio)}
                           type="danger"
                           icon={<DeleteOutlined />}
                        />
                     </Tooltip>
                  </Space>
               )}
            />
         </Table>

         {/* modal para editar premio */}
         <Modal
            id="modal_form"
            className="ModalR"
            title={titlemodal}
            onCancel={() => handleCancel()}
            visible={isOpenModal}
            footer={null}
         >
            <FormRegister
               typefunction={typefunction}
               premio={premioform}
               handleCancel={handleCancel}
            />
         </Modal>
      </>
   );
};

export default ViewRegisterPremio;
