import { Card, Col, Row } from 'antd';
import { FileDoneOutlined, UsergroupDeleteOutlined, ExceptionOutlined } from "@ant-design/icons";
import "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useFirestore } from "reactfire";
const { Meta } = Card;

const InfoPlayers  = () => {
 
 
  let db = useFirestore();
  let[datplayers, setDataplayers]  = useState();
  let [factapproved, setfactapproved] = useState();
  let [factunconfirmed, setfactfactunconfirmed] = useState();

  useEffect(() => {
    db.collection("jugadores").onSnapshot(function (querySnapshot) {
      var players = [];
      querySnapshot.forEach(function (doc) {
        players.push(doc.data());
      });

      
      setDataplayers(players);
    });


  }, []);

  

    return(
        <>
        <div className="site-card-wrapper">
    <Row gutter={16}>
      <Col  xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
      <Card>
          <Meta
            avatar={
            <UsergroupDeleteOutlined style={{color: 'white', fontSize:'20px'}}/> 
            }
            title="PREMIOS"
          />
         <h1 style={{fontSize: '30px', color: 'white'}}>{datplayers && datplayers.length}</h1>
        </Card>
      </Col>
     
    </Row>
  </div>
  </>
    )
}


export default InfoPlayers;
