import "./App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import AdminLayout from "./admin/admin.jsx";
import InvitedLayout from "./admin/invitado.jsx";
import LoginClaro from "./Login";
import { useUser } from "reactfire";
import "firebase/auth";

function App() {
   const userSaved =
      sessionStorage.getItem("userInv") || sessionStorage.getItem("userAdmin");
   const user = useUser();
   const userLogued = user || userSaved;
  //  console.log("userLogued ", userLogued);

   return (
      <Router>
         {!userLogued && <Redirect to="/login" />}

         <Switch>
            {userLogued === "administrador" && (
               <Route path="/admin" component={AdminLayout} />
            )}
            {userLogued === "invitado" && (
               <Route path="/invited" component={InvitedLayout} />
            )}
            <Route path="/login" component={LoginClaro} />
            <Route path="/" component={LoginClaro} />
         </Switch>
      </Router>
   );
}

export default App;
