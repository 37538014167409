import { Form, Input, Button, Select, InputNumber, Upload, Row, message, Col } from "antd";
import { useStorage, useFirestore } from "reactfire";
import { MSJOK, MSJERROR } from "../../mensajes/index";
import { PlusCircleOutlined, EditOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const { Option } = Select;
const { TextArea } = Input;

const layout = {
   labelCol: {
      span: 16,
   },
   wrapperCol: {
      span: 8,
   },
};
const tailLayout = {
   wrapperCol: {
      offset: 8,
      span: 8,
   },
};

const FormRegister = ({ typefunction, premio, handleCancel }) => {
   let editableElement = sessionStorage.getItem('editableElement') === 'true';
   
   const [form] = Form.useForm();
   let [filetosave, setFiletoSave] = useState(null);
   let db = useFirestore();
   let storageFirebase = useStorage();
   //Hora y fecha actual para el documento enviado
   let today = new Date();
   let dateFormat = `${today.getDay()}-${today.getMonth()}-${today.getFullYear()}-${today.toLocaleTimeString()}`;

   useEffect(() => {
      if (typefunction == 1) {         
         form.setFieldsValue({
            nombre: "",            
            descripcion: "",
            categoria: "",
            color: "",
            valorEnPuntos: 0,
            unidades: 0,
            //tallas
            xs: 0,
            s: 0,
            m: 0,
            l: 0,
            xl: 0,
            unica: 0,
            //cantidad minima antes de alerta
            cant_minima: 1,
         });
      } else {         
         form.setFieldsValue({
            nombre: premio.nombre,            
            descripcion: premio.descripcion,
            categoria: premio.categoria,
            color: premio.color,
            valorEnPuntos: premio.valorEnPuntos,
            unidades: premio.unidades,
            //tallas
            xs: premio.xs,
            s: premio.s,
            m: premio.m,
            l: premio.l,
            xl: premio.xl,
            unica: premio.unica,
            cant_minima: premio.cant_minima,
         });
      }
   }, [typefunction, premio]);

   const onFinish = (values) => {            
      if(filetosave){
         var name = filetosave.name;
         var storageRef = storageFirebase.ref("catalogo/" + dateFormat + "_" + name);
         var uploadTask = storageRef.put(filetosave);
         uploadTask.on(
            "state_changed",
            function (snapshot) {
               var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            function (error) {
               console.log(error);
            },
            function () {
               uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                  //console.log("File available at", downloadURL);
               let {
                  nombre,
                  descripcion,
                  categoria,
                  color,
                  valorEnPuntos,
                  unidades,
                  xs,
                  s,
                  m,
                  l,
                  xl,
                  unica,
                  cant_minima,
               } = values;
               
               unidades = xs + s + m + l + xl + unica;
               if (typefunction === 1) {                  
                  db.collection("inventario_prem")
                     .doc(`${values.nombre} ${values.color}`)
                     .set({
                        nombre: `${nombre} ${color}`,
                        descripcion: descripcion,
                        categoria: categoria,
                        color: color,
                        valorEnPuntos: valorEnPuntos,
                        unidades: unidades,
                        //tallas
                        xs: xs,
                        s: s,
                        m: m,
                        l: l,
                        xl: xl,
                        unica: unica,
                        cant_minima: cant_minima,
                        preview: downloadURL,
                     })
                     .then(function () {
                        MSJOK("PRODUCTO GUARDADO");
                        handleCancel();
                        window.location.reload();
                     })
                     .catch(function (error) {
                        MSJERROR("ERROR AL GUARDAR EL PRODUCTO");                        
                     });
               } else {
                  var premioref = db
                     .collection("inventario_prem")
                     .doc(values.nombre);

                  return premioref
                     .update({
                        nombre: values.nombre,
                        categoria: categoria,
                        color: color,
                        valorEnPuntos: valorEnPuntos,
                        unidades: unidades,
                        // tallas
                        xs: xs,
                        s: s,
                        m: m,
                        l: l,
                        xl: xl,
                        unica: unica,
                        cant_minima: cant_minima,
                        preview: downloadURL,
                     })
                     .then(function () {
                        MSJOK("PRODUCTO ACTUALIZADO");
                        handleCancel();
                        window.location.reload();
                     })
                     .catch(function (error) {
                        console.log('error', error);
                        MSJERROR("ERROR AL ACTUALIZAR EL PRODUCTO");                        
                     });
               }
            });            
         }
      );
      }
      else
      {
         let {
            nombre,
            descripcion,
            categoria,
            color,
            valorEnPuntos,
            unidades,
            xs,
            s,
            m,
            l,
            xl,
            unica,
            cant_minima,
         } = values;
         
         unidades = xs + s + m + l + xl + unica;
         if (typefunction === 1) {
            db.collection("inventario_prem")
               .doc(`${values.nombre} ${values.color}`)
               .set({
                  nombre: `${nombre} ${color}`,
                  descripcion: descripcion,
                  categoria: categoria,                  
                  color: color,
                  valorEnPuntos: valorEnPuntos,
                  unidades: unidades,
                  //tallas
                  xs: xs,
                  s: s,
                  m: m,
                  l: l,
                  xl: xl,
                  unica: unica,
                  cant_minima: cant_minima,                  
               })
               .then(function () {
                  MSJOK("PRODUCTO GUARDADO");
                  handleCancel();
                  window.location.reload();
               })
               .catch(function (error) {
                  console.log('error', error);
                  MSJERROR("ERROR AL GUARDAR EL PRODUCTO");                  
               });
         } else {
            var premioref = db
               .collection("inventario_prem")
               .doc(values.nombre);

            return premioref
               .update({
                  nombre: values.nombre,
                  descripcion: descripcion,
                  categoria: categoria,                  
                  valorEnPuntos: valorEnPuntos,
                  unidades: unidades,
                  // tallas
                  xs: xs,
                  s: s,
                  m: m,
                  l: l,
                  xl: xl,
                  unica: unica,
                  cant_minima: cant_minima,                  
               })
               .then(function () {
                  MSJOK("PRODUCTO ACTUALIZADO");
                  handleCancel();
                  window.location.reload();
               })
               .catch(function (error) { 
                  console.log('error', error);                 
                  MSJERROR("ERROR AL ACTUALIZAR EL PRODUCTO");                  
               });
         }
      }           
   };

   const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
   };

   const props = {
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      listType: "picture",
      maxCount: 1,
      headers: {
         authorization: "authorization-text",         
      },
      previewFile(file) {
         setFiletoSave(file);         
         return fetch("https://next.json-generator.com/api/json/get/4ytyBoLK8", {
            method: "POST",
            body: file,            
         })
            .then((res) => {console.log(res.json())})
            .then(({ thumbnail }) => thumbnail);
      },
   };

   return (
    <Row justify='center'>
      <Form
         form={form}
         name="basic"
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
      >
         <Form.Item
            label="Nombre"
            name="nombre"            
            rules={[
               {
                  required: true,
                  message: "Digite nombre",
               },
            ]}
         >
            <Input disabled={!editableElement} />
         </Form.Item>
         
         <Form.Item
            label="Descripción"
            name="descripcion"
            rules={[
               {
                  required: true,
                  message: "Digite la descripcion",
               },
            ]}
         >
            <TextArea />
         </Form.Item>

         <Form.Item
            label="Categoría"
            name="categoria"
            rules={[
               {
                  required: true,
                  message: "Selecione la categoría",
               },
            ]}
         >
            <Select>
               <Option value="unisex">Unisex</Option>
               <Option value="masculino">Masculino</Option>
               <Option value="femenino">Femenino</Option>
            </Select>
         </Form.Item>

         <Form.Item
            label="Color"
            name="color"
            rules={[
               {
                  required: true,
                  message: "Digite color",
               },
            ]}
         >
            <Input disabled={!editableElement} />
         </Form.Item>

         <Form.Item
            label="Valor en puntos"
            name="valorEnPuntos"            
            rules={[
               {
                  required: true,
                  message: "Digite el valor en puntos",
               },
            ]}
         >
            <InputNumber min={0} />
         </Form.Item>   

         <Form.Item hidden="true" label="Unidades" name="unidades">
            <InputNumber min={1} />
         </Form.Item>
         <Form.Item
            label="Cantidad Mínima Para Alerta"
            name="cant_minima"
            rules={[
               {
                  required: true,
                  message: "Digite la cantidad mínima antes de alerta",
               },
            ]}
         >
            <InputNumber min={1} />
         </Form.Item>
         <div>
            <span>Cantidades en Tallas:</span>
            <Row gutter={[4,4]} justify='center'>
               <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                   <Form.Item
                     label="XS"
                     name="xs"
                     rules={[
                        {
                           required: true,
                           message: "Digite unidades de la talla XS",
                        },
                     ]}
                  >
                     <InputNumber min={0} />
                  </Form.Item>
               </Col>
               <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                  <Form.Item
                     label="S"
                     name="s"
                     rules={[
                        {
                           required: true,
                           message: "Digite unidades de la talla S",
                        },
                     ]}
                  >
                     <InputNumber min={0} />
                  </Form.Item>
               </Col>
               <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                <Form.Item
                     label="M"
                     name="m"
                     rules={[
                        {
                           required: true,
                           message: "Digite unidades de la talla M",
                        },
                     ]}
                  >
                     <InputNumber min={0} />
                  </Form.Item>
               </Col>
               <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                  <Form.Item
                     label="L"
                     name="l"
                     rules={[
                        {
                           required: true,
                           message: "Digite unidades de la talla L",
                        },
                     ]}
                  >
                     <InputNumber min={0} />
                  </Form.Item>
               </Col>
               <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                 <Form.Item
                     label="XL"
                     name="xl"
                     rules={[
                        {
                           required: true,
                           message: "Digite unidades de la talla XL",
                        },
                     ]}
                  >
                     <InputNumber min={0} />
                  </Form.Item>
               </Col>
               <Col xs={12} sm={12} md={12} lg={3} xl={3} xxl={3}>
                 <Form.Item
                     label="Única"
                     name="unica"
                     rules={[
                        {
                           required: true,
                           message: "Digite unidades de la talla unica",
                        },
                     ]}
                  >
                     <InputNumber min={0} />
                  </Form.Item>
               </Col>
            </Row>

            <Row justify="center">               
               <Upload {...props} accept="image/*" name="preview">
                  <Button
                     style={{
                        display: "flex",
                        justifyContent: "space-around",
                     }}
                     type="default"
                     size="small"
                     icon={<UploadOutlined />}
                     style={{ fontSize: "15px" }}
                  >
                     Imagen Producto
                  </Button>
               </Upload>
            </Row>
            <br />
         </div>

         <Form.Item>
            {typefunction === 1 ? (
               <Button
                  shape="round"
                  icon={<PlusCircleOutlined />}
                  type="primary"
                  htmlType="submit"
               >
                  GUARDAR
               </Button>
            ) : (
               <Button
                  shape="round"
                  icon={<EditOutlined />}
                  type="primary"
                  htmlType="submit"
               >
                  EDITAR
               </Button>
            )}
         </Form.Item>
      </Form>
   </Row>
   );
};

export default FormRegister;
