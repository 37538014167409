import { Row, Col } from "antd";
import ViewOrderRegister from "./ViewOrderRegister";

const Content = ({ nameadmin }) => {
   return (
      <Row>
         <Col span={24} style={{ marginTop: "10px" }}>
            <ViewOrderRegister nameadmin={nameadmin} />
         </Col>
      </Row>
   );
};

export default Content;
